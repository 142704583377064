<template>
  <div :class="containerClass">
    <div :class="imageContainerClass">
      <div
        :class="backgroundImageClass"
        :style="backgroundImageStyle"
      />
      <img
        v-if="appImage"
        :class="appImageClass"
        :src="appImage"
      >
    </div>
    <div :class="copyContainerClass">
      <h2 :class="headerClass">
        {{ headerText }}
      </h2>
      <p :class="copyClass">
        {{ copyText }}
      </p>
    </div>
  </div>
</template>

<script>
export const sellingPointFeatures = {
  forecasts10day: 'forecasts10day',
  expertAnalysis: 'expertAnalysis',
  maps3d: 'maps3d',
  forecastAnywhere: 'forecastAnywhere',
  trustedByMillions: 'trustedByMillions',
  climateChange: 'climateChange',
};

export default {
  name: 'SellingPointFeature',

  props: {
    alignImageRight: {
      type: Boolean,
      default: false,
    },
    backgroundImageCover: {
      type: Boolean,
      default: false,
    },
    sellingPointFeature: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(sellingPointFeatures).includes(value);
      },
    },
  },

  computed: {
    appImage() {
      if (this.isExpertAnalysis) {
        return 'https://blizzard.opensnow.com/app-images/500x1004/dailysnow-jacksonhole-16.png';
      }

      if (this.isForecastAnywhere) {
        return 'https://blizzard.opensnow.com/app-images/500x1004/forecast-anywhere-map-norway-16-2.png';
      }

      if (this.isForecasts10day) {
        return 'https://blizzard.opensnow.com/app-images/500x1004/favorite-locations-alta-highlands-taos-16.png';
      }

      if (this.isMaps3d) {
        return 'https://blizzard.opensnow.com/app-images/500x1004/3d-maps-16.png';
      }

      if (this.isTrustedByMillions) {
        return 'https://blizzard.opensnow.com/app-images/500x1004/photo-powder-skier-16.png';
      }

      if (this.isClimateChange) {
        return 'https://blizzard.opensnow.com/app-images/500x1004/stripe-climate-change-16.png';
      }

      return null;
    },

    appImageClass() {
      return [
        'tw-absolute',
        this.alignImageRight ? 'tw-left-4 lg:tw-left-0' : 'tw-right-4 lg:tw-right-0',
        'tw-w-auto',
        this.$style.appImage,
      ];
    },

    backgroundImageClass() {
      return [
        'tw-absolute tw-top-0',
        'tw-h-full',
        'tw-bg-no-repeat',
        this.backgroundImageCover ? 'tw-bg-cover' : 'tw-bg-contain sm:tw-bg-cover',
        this.$style.backgroundImage,
        this.alignImageRight ? this.$style.backgroundImageRight : null,
      ];
    },

    backgroundImageStyle() {
      /* eslint global-require: off */

      const getBackgroundImage = () => {
        if (this.isClimateChange) {
          return 'https://blizzard.opensnow.com/images/home-page/climate-change-2.jpg';
        }

        if (this.isExpertAnalysis) {
          return 'https://blizzard.opensnow.com/images/home-page/daily-analysis-2.jpg';
        }

        if (this.isForecastAnywhere) {
          return 'https://blizzard.opensnow.com/images/home-page/forecast-anywhere-2.jpg';
        }

        if (this.isForecasts10day) {
          return 'https://blizzard.opensnow.com/images/home-page/powder-chasing-tools-2.jpg';
        }

        if (this.isMaps3d) {
          return 'https://blizzard.opensnow.com/images/home-page/track-storms-2.jpg';
        }

        if (this.isTrustedByMillions) {
          return 'https://blizzard.opensnow.com/images/home-page/trusted-by-millions-2.jpg';
        }

        return null;
      };

      return {
        backgroundImage: `linear-gradient(var(--home-page-overlay-start), var(--home-page-overlay-end)), url(${getBackgroundImage()})`,
      };
    },

    containerClass() {
      return [
        'lg:tw-flex lg:tw-justify-evenly',
        this.$style.container,
      ];
    },

    copyClass() {
      return [
        'tw-px-4 tw-text-center',
        this.$style.copy,
      ];
    },

    copyContainerClass() {
      return [
        'tw-px-4 lg:tw-px-12',
        'lg:tw-self-stretch',
        this.alignImageRight ? 'tw-order-1' : 'tw-order-2',
        this.$style.copyContainer,
      ];
    },

    copyText() {
      if (this.isClimateChange) {
        return "Temperatures are warming and that's not good for snow. That's why we partner with Stripe to send 1% of our All-Access sales to innovative projects that directly remove carbon dioxide from the atmosphere and ensure its sequestration in secure, long-term storage. This alone will not solve climate change, but it's a start.";
      }

      if (this.isExpertAnalysis) {
        return 'Instead of spending hours sifting through weather data, get the inside scoop in just a few minutes. Our local experts write a new "Daily Snow" forecast each day for regions around the US, Canada, Europe, Scandinavia, Australia, and New Zealand. Have one of our expert local forecasters guide you to the best conditions.';
      }

      if (this.isForecastAnywhere) {
        return 'Our weather forecasts are available for any location on Earth. This means that you can view our weather forecasts for your favorite ski resort, backcountry ski location, or camping destination. Save up to 15 custom locations to view on your Favorites screen for convenient access to the latest conditions and 10-day forecast.';
      }

      if (this.isForecasts10day) {
        return 'Finding the location that has the best conditions can feel overwhelming. With OpenSnow, deciding where to go is easy. View the latest 10-day multi-model weather forecast, snow report, and mountain cams for your favorite locations in just a few seconds.';
      }

      if (this.isMaps3d) {
        return 'We make it easy to track incoming storms with current and forecast radar over high-resolution 3D terrain and satellite maps. You can also view forecast animations for air quality and wildfire smoke, along with the current lightning risk, hail size, snow depth, avalanche forecast, active fire perimeters, public and private land ownership maps, and more.';
      }

      if (this.isTrustedByMillions) {
        return 'As adventure-focused meteorologists, we started OpenSnow to make it easy for our friends and family to find their next powder day. Now over 10 years later, we reach millions of people and still obsess about making the forecast more accurate and creating features to make finding your ideal conditions even easier.';
      }

      return null;
    },

    headerClass() {
      return [
        'tw-my-6 lg:tw-mt-0 lg:tw-mb-10',
        'tw-text-center tw-font-medium',
        'home-page-section-title',
      ];
    },

    headerText() {
      if (this.isClimateChange) {
        return 'Climate Change Commitment';
      }

      if (this.isExpertAnalysis) {
        return 'Read Expert Analysis';
      }

      if (this.isForecastAnywhere) {
        return 'Get Forecast Anywhere';
      }

      if (this.isForecasts10day) {
        return 'Compare 10-Day Forecasts';
      }

      if (this.isMaps3d) {
        return 'View 3D Maps';
      }

      if (this.isTrustedByMillions) {
        return '10+ Years In, Trusted By Millions';
      }

      return null;
    },

    isClimateChange() {
      return this.sellingPointFeature === sellingPointFeatures.climateChange;
    },

    isExpertAnalysis() {
      return this.sellingPointFeature === sellingPointFeatures.expertAnalysis;
    },

    isForecastAnywhere() {
      return this.sellingPointFeature === sellingPointFeatures.forecastAnywhere;
    },

    isForecasts10day() {
      return this.sellingPointFeature === sellingPointFeatures.forecasts10day;
    },

    isMaps3d() {
      return this.sellingPointFeature === sellingPointFeatures.maps3d;
    },

    isTrustedByMillions() {
      return this.sellingPointFeature === sellingPointFeatures.trustedByMillions;
    },

    imageContainerClass() {
      return [
        'tw-relative',
        'lg:tw-self-stretch',
        this.alignImageRight ? 'tw-order-2' : 'tw-order-1',
        this.$style.imageContainer,
      ];
    },
  },
};
</script>

<style module>
.container {
  --home-page-overlay-start: rgb(255 255 255 / 20%);
  --home-page-overlay-end: rgb(255 255 255 / 40%);
}

[data-theme="dark"] .container {
  --home-page-overlay-start: rgb(0 0 0 / 20%);
  --home-page-overlay-end: rgb(0 0 0 / 40%);
}

@media (min-width: 992px) {
  .container {
    min-height: 620px;
  }
}

@media (min-width: 992px) {
  .copyContainer {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .copyContainer {
    width: 30%;
  }
}

.copy {
  font-size: 1rem;
  line-height: 1.7;
}

@media (min-width: 992px) {
  .copy {
    font-size: 1.3rem;
  }
}

.imageContainer {
  height: 350px;
}

@media (min-width: 992px) {
  .imageContainer {
    height: auto;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .imageContainer {
    width: 70%;
  }
}

.backgroundImage {
  background-position: 50%;
  left: 0;
  width: 100%;
  z-index: 1;
}

@media (min-width: 992px) {
  .backgroundImage {
    width: 90%;
  }

  .backgroundImageRight {
    left: unset;
    right: 0;
  }
}

.appImage {
  height: 300px;
  top: calc(50% - 150px);
  z-index: 2;
}

@media (min-width: 992px) {
  .appImage {
    height: 400px;
    top: calc(50% - 200px);
  }
}
</style>
